Drupal.behaviors.gnavCart = (function ($, _, site, generic) {
  'use strict';

  // Private variables:
  var $blocks = $();
  var $trigger = $();
  var $counters = $();
  var contextObj = null;
  var data = {
    item_count: 0,
    subtotal: '',
    points: 0,
    order_within: '',
    ship_sooner_date: '',
    items: [],
    singular: 0,
    new_items: []
  };
  var state = 'empty';
  var loaded = false;
  var closeNav = false;
  var closeTimeout;
  var gNav = site.globalNavigation;

  // Replace dots in the top-level key names the server is giving us.
  // 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
  function _normalizeResponseKeys(items) {
    var replaceKey = function (key) {
      return key.replace(/\./, '_');
    };
    var normalizeArray = function (array) {
      var newArray = [];

      for (var x = 0, alen = array.length; x < alen; x++) {
        var innerObj = {};

        if (typeof array[x] == 'string') {
          // When the array just has a string, just push that.
          newArray.push(array[x]);
        } else {
          // When the array is anything else, just push that.
          for (var innerKey in array[x]) {
            innerObj[replaceKey(innerKey)] = array[x][innerKey];
          }
          newArray.push(innerObj);
        }
      }

      return newArray;
    };
    var out = [];

    for (var i = 0, len = items.length; i < len; i++) {
      out[i] = {};
      for (var key in items[i]) {
        if (items[i].hasOwnProperty(key)) {
          out[i][replaceKey(key)] = items[i][key];
        }
      }
    }

    return out;
  }

  function _setCloseTimeout() {
    if (state !== 'added') {
      return;
    }

    closeTimeout = setTimeout(function () {
      if (closeNav) {
        // Drupal.behaviors.gnav.close();
        // ghetto, but gets the job done
        $('.js-gnav-util-close').trigger('click');
        _gNavClose();
      }
      behavior.setState();
    }, 5000);
  }

  function _gNavClose() {
    if (gNav != null) {
      gNav.$backdrop.fadeOut(gNav.transitionTime, function () {
        gNav.$activeSubnav = 0;
      });
    }
  }

  function _clearCloseTimeout() {
    clearTimeout(closeTimeout);
  }

  // Public methods:
  var behavior = {
    attach: function (context) {
      $trigger = $trigger.add($('.gnav-util--cart', context)).first();
      $blocks = $blocks.add($('.js-gnav-util__content__inner--cart', context));
      $counters = $counters.add($('.gnav-util__icon__cart-count', context));

      // Get the initial item count from the cookie to avoid unnecessary trips
      // to the server.
      this.setData({ item_count: site.userInfoCookie.getValue('item_count') - 0 });

      contextObj = context;
    },

    render: function () {
      var rendered = site.template.get({
        name: 'gnav_cart_content',
        data: data
      });

      // Some of the field values may actually contain mustache themselves, so
      // this template needs to be run through rendering a second time.
      rendered = site.template.render(rendered, data);

      $blocks.html(rendered);

      // Update the counters that are outside of the template
      $counters.text(data.item_count);

      return this;
    },

    load: function (force) {
      if (loaded && (!_.isBoolean(force) || !force)) {
        return this;
      }

      $blocks.addClass('loading');

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          $blocks.removeClass('loading');

          var value = response.getValue();
          var cartItems = value.order.items.concat(value.order.samples);

          if (_.isUndefined(value) || !value) {
            return;
          }
          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: _normalizeResponseKeys(cartItems)
          });
          behavior.setOverlayHeight(contextObj);
        },
        onError: function () {
          $blocks.removeClass('loading');
          // @TODO: a failure message should go here.
          loaded = false;
        }
      });

      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      loaded = true;

      return this;
    },

    addItem: function (result) {
      if (_.isUndefined(result) || !result || _.isUndefined(result.trans_data) || _.isUndefined(result.ac_results)) {
        return this;
      }

      var resultType = this.getResultType(result.ac_results);

      if (resultType === 'replenishment') {
        // remove result.type REPL from results
        // causes a duplicate sku to show in cart dropdown
        result.ac_results = _.reject(result.ac_results, function (result) {
          return result.type == 'REPL';
        });
      }

      var addedItems = '';

      addedItems = _.map(result.ac_results, function (value) {
        var res = value.result;
        var item = res.CARTITEM;

        // Seems very dumb to calculate this on the front end.
        item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

        return item;
      });

      this.setData({
        subtotal: result.trans_data.formattedSubtotal,
        points: result.trans_data.points === 0 ? 0 : result.trans_data.points || data.points,
        items: _normalizeResponseKeys(result.trans_data.order.items),
        item_count: result.trans_data.items_count,
        new_items: _normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      this.setState('added');

      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans_data.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      var $body = $('body');

      $body.toggleClass('gnav-active');
      var cartUtil = $('.js-gnav-util-trigger--cart');
      var cartUtilContent = cartUtil.next('.js-gnav-util__content');
      var cartUtilClose = cartUtilContent.find('a.js-gnav-util-close');

      if (gNav != null) {
        gNav.$backdrop.show();
        gNav.$backdrop.on('click', function () {
          _gNavClose();
        });
        cartUtilClose.off('click').on('click', function (e) {
          e.preventDefault();
          cartUtil.removeClass('active');
          cartUtilContent.hide();
          _gNavClose();
          behavior.setState();
        });
      }
      cartUtil.addClass('active');
      _setCloseTimeout();

      return this;
    },

    addOffer: function (result) {
      if (_.isUndefined(result) || !result || _.isUndefined(result.trans) || _.isUndefined(result.items)) {
        return this;
      }
      // var resultType = this.getResultType(result.ac_results);

      // var addedItems = '';
      // addedItems = _.map(result.ac_results, function(value) {
      //   var item = result.items;

      //   // Seems very dumb to calculate this on the front end.
      //   item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

      //   return item;
      // });

      this.setData({
        subtotal: result.trans.formattedSubtotal,
        points: result.trans.points === 0 ? 0 : result.trans.points || data.points,
        items: _normalizeResponseKeys(result.trans.order.items),
        item_count: result.trans.items_count,
        new_items: _normalizeResponseKeys(result.trans.order.samples)
      });

      // Temporarily set the added state:
      this.setState('added');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    setOverlayHeight: function (context) {
      // There are two of these on the site (network and non-network), so
      // grab the visible one for the correct height values later.
      var $bagContents = $('.js-cart-block-container.js-cart-visible', context);
      var $siteHeader = $('.site-header', context);
      // set height of entire overlay to window height, less gnav offset
      var siteHeaderHeight = $siteHeader.outerHeight(true);
      var overlayHeight = $(window).height() - siteHeaderHeight;

      $bagContents.height('auto');
      $bagContents.css('max-height', overlayHeight);

      // set height of product list to available space
      var cartHeaderHeight = $bagContents.find('.cart-block__header').outerHeight(true);
      var cartFooterHeight = $bagContents.find('.cart-block__footer').outerHeight(true);
      var $cartProductsContainer = $bagContents.find('.cart-block__products');
      var productsHeight = overlayHeight - cartHeaderHeight - cartFooterHeight;

      $cartProductsContainer.height('auto');
      $cartProductsContainer.css('max-height', productsHeight);

      // open via gnav.js
      site.globalNavigation.route($bagContents, 'cart');
    },

    // Setters:
    setState: function (newState) {
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        state = data.item_count > 0 ? 'nonempty' : 'empty';
      }
      // Sanity check:
      else if (!_.contains(states, newState)) {
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        state = newState;
      }

      $blocks.removeClass(stateClasses).addClass(classPrefix + state);
      $trigger.toggleClass('gnav-util--cart--nonempty', state !== 'empty');

      return this;
    },

    setData: function (newData) {
      _.extend(data, newData);
      data.singular = data.item_count === 1;

      this.setState().render();

      return this;
    },

    // Getters:
    getState: function () {
      return state;
    },

    getData: function (key) {
      return _.isUndefined(key) ? data : data[key];
    },

    getResultType: function (results) {
      var type = 'sku';
      var isReplenishment = _.filter(results, function (result) {
        return result.instance == 'alter_replenishment' && result.type == 'REPL';
      });

      if (isReplenishment.length > 0) {
        type = 'replenishment';
      }

      return type;
    }
  };

  // Document listeners:
  $(document).on('offerToCart.success', function (event, result) {
    $('.js-cart-block-container').removeClass('hidden');
    behavior.addOffer(result);
  });

  // Document listeners:
  $(document).on('addToCart.success', function (event, result) {
    // @setup new brand - this is temporary open/close for testing add to cart
    // a more integrated method with all gnav should be done per brand
    $('.js-cart-block-container').removeClass('hidden').show();
    behavior.addItem(result);
  });

  $(document).on('loadCart.success', function (event, force) {
    behavior.load(true);
  });

  // Gnav Remove Cart items
  $(document).on('click', '.js-gnav-cart-item-remove', function (event, args) {
    event.preventDefault();
    // remove link ID must be in format: "remove_[BASE SKU ID]"
    var baseSkuId = $(this)[0].id.replace(/remove_/, '');
    // remove form ID must be in format: "remove_form_[BASE SKU ID]"
    var removeForm = $('form.js-remove-form-' + baseSkuId);

    if (!removeForm[0]) {
      return null;
    }

    var pObj = {};
    var params = removeForm.serializeArray();

    for (var i = 0, len = params.length; i < len; i++) {
      pObj[params[i].name] = params[i].value;
    }

    function cartReload() {
      if ($('.active-panel-cart').length > 0 || $('.active-panel-signin').length > 0) {
        if (site.panels !== undefined) {
          site.panels.reloadPanels();
        }
      }
      $(document).trigger('loadCart.success');
    }

    generic.jsonrpc.fetch({
      method: 'logic.checkout--viewcart',
      params: [pObj],
      onSuccess: function (r) {
        cartReload();
      },
      onFailure: function (r) {
        cartReload();
      }
    });
  });

  $(window).resize(
    _.debounce(function () {
      if ($('body', contextObj).hasClass('gnav-active') && gNav.isDesktop) {
        behavior.setOverlayHeight(contextObj);
      }
    }, 250)
  );

  $(document).on('mouseenter', '.js-cart-block-container', _clearCloseTimeout);
  $(document).on('mouseleave', '.js-cart-block-container', _setCloseTimeout);

  $(document).on('click', '.cart-block__items__view-bag', function (event) {
    event.preventDefault();

    _clearCloseTimeout();
    behavior.setState().render();
  });

  $(document).on('click', '.js-gnav-util-trigger--cart', function (event, args) {
    event.preventDefault();

    if ($('.gnav-util--cart').length) {
      // Override preventDefault on gnav overlay logic if cart is empty:
      if (state === 'empty' && data.item_count == 0) {
        return true;
      }

      // Do nothing if the cart is empty:
      if (state === 'empty' || state === 'added') {
        return;
      }

      var $thisContent = $(this).next('.js-gnav-util__content');

      $thisContent.addClass('js-cart-visible');
      // var $blockContent = $('.js-gnav-util__content', contextObj);
      // var $trigger = $('.js-gnav-util-trigger', contextObj);
      // $blockContent.not($thisContent).addClass('hidden');
      // $trigger.removeClass('active');
      // $(this).toggleClass('active', $thisContent.hasClass('hidden'));
      // $thisContent.toggleClass('hidden');

      $('body').removeClass('mobile-gnav-active');
      $thisContent.show();
      // $body.toggleClass('gnav-active');
      behavior.load();
      behavior.setOverlayHeight(contextObj);
    }
  });

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {})
);
